import React from 'react'
import FreeTips from '../Compnents/landingpages/Services/FreeTips/FreeTips'
import Footer from '../Footer'
import Header from '../Header'
import Layout from './Layout'
// import { useLocation } from 'react-router-dom'

const Freetip = (props) => {
    //  const routepath = useLocation()
    //  useEffect(() => {
    //    window.scrollTo(0, 0)
    //  }, [routepath])
  return (
    <>
    <Layout>
        <Header/>
        <FreeTips/>
        <Footer/>
    </Layout>
    </>
  )
}

export default Freetip