import React from 'react'
import freeimg from '../../../Images/safety222.png'
// import acromny from '../../../Images/Rectangle 36.png'

import '../FreeTips/FreeTips.css'
// import { useLocation } from 'react-router-dom'
const offences = [
  {
    id: 1,
    title: 'LIGHT/SIGN VIOLATION',
    code: 'LSV',
    points: 2,
    penalty: '2,000',
    category: 2,
  },
  {
    id: 2,
    title: 'ROAD OBSTRUCTION',
    code: 'ROB',
    points: 3,
    penalty: '3,000',
    category: 1,
  },
  {
    id: 3,
    title: 'ROUTE VIOLATION',
    code: 'RTV',
    points: 5,
    penalty: '5,000',
    category: 1,
  },
  {
    id: 4,
    title: 'SPEED LIMIT VIOLATION',
    code: 'SLV',
    points: 3,
    penalty: '3,000',
    category: 1,
  },
  {
    id: 5,
    title: 'VEHICLE LICENCE VIOLATION',
    code: 'VLV',
    points: 3,
    penalty: '3,000',
    category: 2,
  },
  {
    id: 6,
    title: 'VEHICLE NUMBER PLATE VIOLATION',
    code: 'NPV',
    points: 3,
    penalty: '3,000',
    category: 1,
  },
  {
    id: 7,
    title: 'DRIVER’S LICENCE VIOLATION',
    code: 'DLV',
    points: 10,
    penalty: '10,000',
    category: 2,
  },
  {
    id: 8,
    title: 'WRONGFUL OVERTAKING',
    code: 'WOV',
    points: 3,
    penalty: '3,000',
    category: 1,
  },
  {
    id: 9,
    title: 'ROAD MARKING VIOLATION',
    code: 'RMV',
    points: 5,
    penalty: '5,000',
    category: 1,
  },
  {
    id: 10,
    title: 'CAUTION SIGN VIOLATION',
    code: 'CSV',
    points: 3,
    penalty: '3,000',
    category: 3,
  },
  {
    id: 11,
    title: 'DANGEROUS DRIVING',
    code: 'DGD',
    points: 10,
    penalty: '50,000',
    category: 1,
  },
  {
    id: 12,
    title: 'DRIVING UNDER ALCOHOL/DRUG INFLUENCE',
    code: 'DUI',
    points: 5,
    penalty: '5,000',
    category: 2,
  },
  {
    id: 13,
    title: 'OPERATING A VEHICLE WITH FORGED DOCUMENTS',
    code: 'OFD',
    points: 10,
    penalty: '20,000',
    category: 2,
  },
  {
    id: 14,
    title: 'UNAUTHORIZED REMOVAL OF OR TAMPERING WITH ROAD SIGN',
    code: 'UTS',
    points: 5,
    penalty: '5,000',
    category: '1',
  },
  {
    id: 15,
    title: 'DO NOT MOVE VIOLATION',
    code: 'DNM',
    points: 2,
    penalty: '2,000',
    category: 2,
  },
  {
    id: 16,
    title: 'INADEQUATE CONSTRUCTION WARNING SIGN',
    code: 'ICW',
    points: '-',
    penalty: '50,000',
    category: 1,
  },
  {
    id: 17,
    title: 'CONSTRUCTION AREA SPEED LIMIT VIOLATION',
    code: 'CAV',
    points: '3',
    penalty: '3,000',
    category: 1,
  },
  {
    id: 18,
    title: 'FAILURE TO MOVE OVER',
    code: 'FMO',
    points: 3,
    penalty: '3,000',
    category: 1,
  },
  {
    id: 19,
    title: 'FAILURE TO COVER UNSTABLE MATERIALS',
    code: 'FCM',
    points: 5,
    penalty: '5,000',
    category: 1,
  },
  {
    id: 20,
    title: 'OVERLOADING',
    code: 'OVL',
    points: 10,
    penalty: '10,000',
    category: 1,
  },
  {
    id: 21,
    title: 'DRIVING WITH WORN- OUT TYRE OR WITHOUT SPARE TYRE',
    code: 'TTY',
    points: 3,
    penalty: '3,000',
    category: 1,
  },
  {
    id: 22,
    title: 'DRIVING WITHOUT OR WITH SHATTERED WINDSCREEN',
    code: 'VWV',
    points: 2,
    penalty: '2,000',
    category: 1,
  },
  {
    id: 23,
    title: 'FAILURE TO FIX RED FLAG ON PROJECTED LOAD',
    code: 'FFF',
    points: 3,
    penalty: '3,000',
    category: 1,
  },
  {
    id: 24,
    title: 'FAILURE TO REPORT ACCIDENT',
    code: 'FRC',
    points: 10,
    penalty: '20,000',
    category: 1,
  },
  {
    id: 25,
    title: 'MEDICAL PERSONNEL/HOSPITAL REJECTION OF ROAD CRASH VICTIM',
    code: 'RCV',
    points: '-',
    penalty: '50,000',
    category: 1,
  },
  {
    id: 26,
    title: 'ASSAULTING MARSHAL ON DUTY',
    code: 'AMD',
    points: 10,
    penalty: '10,000',
    category: '2',
  },
  {
    id: 27,
    title: 'OBSTRUCTING MARSHAL ON DUTY',
    code: 'OMD',
    points: 2,
    penalty: '2,000',
    category: 2,
  },
  {
    id: 28,
    title: 'ATTEMPTING TO CORRUPT MARSHA',
    code: 'ACS',
    points: 10,
    penalty: '10,000',
    category: 2,
  },
  {
    id: 29,
    title: 'DRIVING WITHOUT SPECIFIED FIRE EXTINGUISHER',
    code: 'FEV',
    points: 3,
    penalty: '3,000',
    category: '3',
  },
  {
    id: 30,
    title: 'DRIVING A COMMERCIAL VEHICLE WITHOUT PASSENGER MANIFEST',
    code: 'PMV',
    points: 10,
    penalty: '10,000',
    category: 2,
  },
  {
    id: 31,
    title: 'DRIVING WITHOUT SEAT BELT',
    code: 'SUV',
    points: 2,
    penalty: '2,000',
    category: 1,
  },
  {
    id: 32,
    title: 'USE OF PHONE WHILE DRIVING',
    code: 'UPD',
    points: 4,
    penalty: '4,000',
    category: 1,
  },
  {
    id: 33,
    title: 'DRIVING A VEHICLE WHILE UNDER 18 YEARS',
    code: 'UDR',
    points: '-',
    penalty: '2,000',
    category: 1,
  },
  {
    id: 34,
    title: 'RIDING MOTORCYCLE WITHOUT USING CRASH HELMET',
    code: 'RMH',
    points: 2,
    penalty: '2,000',
    category: 1,
  },

  {
    id: 35,
    title: 'EXCESSIVE SMOKE EMISSION',
    code: 'ESE',
    points: 5,
    penalty: '5,000',
    category: 1,
  },
  {
    id: 36,
    title: 'MECHANICALLY DEFICIENT VEHICLE',
    code: 'MDV',
    points: 5,
    penalty: '5,000',
    category: 1,
  },

  {
    id: 37,
    title: 'FAILURE TO INSTALL SPEED LIMITING DEVICE',
    code: 'FSLD',
    points: 3,
    penalty: '3,000',
    category: 2,
  },
]
const acronmys = [
  {
    id: 1,
    acromny: 'D',
    Meaning: 'Do not DAY DREAM',
  },
  {
    id: 2,
    acromny: 'A',
    Meaning: 'Rapt ATTENTION',
  },
  {
    id: 3,
    acromny: 'S',
    Meaning: 'Maintain SHARP REFLEXES',
  },
  {
    id: 4,
    acromny: 'S',
    Meaning: 'Use SEAT BELTS',
  },
  {
    id: 5,
    acromny: 'C',
    Meaning: 'Proper CHAIR POSITIONING',
  },
  {
    id: 6,
    acromny: 'O',
    Meaning: 'Do not imbibe OKADA MENTALITY',
  },
  {
    id: 7,
    acromny: 'A',
    Meaning: 'AVOID too much discussion',
  },
  {
    id: 8,
    acromny: 'A',
    Meaning: 'AVOID too much quietness (use NO NAP)',
  },
  {
    id: 9,
    acromny: 'A',
    Meaning: 'AVOID answering or making phone calls',
  },
  {
    id: 10,
    acromny: 'D',
    Meaning: 'Know that your car is not a TOY',
  },
  {
    id: 11,
    acromny: 'T',
    Meaning: 'Effective use of TRAFFICATORS (see FRSC guides)',
  },
  {
    id: 12,
    acromny: 'C',
    Meaning: 'Avoid COMPETITIONS while driving',
  },
  {
    id: 13,
    acromny: 'O',
    Meaning: 'Avoid OVER CONFIDENCE',
  },
  {
    id: 14,
    acromny: 'O',
    Meaning: 'Do not OVER SPEED',
  },
  {
    id: 15,
    acromny: 'O',
    Meaning: 'Do not OVER LOAD',
  },
  {
    id: 16,
    acromny: 'O',
    Meaning: 'OBEY traffics rules and road signs (see FRSC guides)',
  },
  {
    id: 17,
    acromny: 'W',
    Meaning: 'Forget your WORRIES while driving',
  },
  {
    id: 18,
    acromny: 'D',
    Meaning: 'Glance at DASHBOARD occasionally',
  },
  {
    id: 19,
    acromny: 'D',
    Meaning: 'Avoid DRUGS AND ALCHOHOLIC BEVERAGES before or while driving',
  },
]

const FreeTips = () => {
  return (
    <>
      <div className='freetippp'>
        <div className='freetipshero d-flex justify-content-between align-items-center flex-column flex-md-row'>
          <div className='herotext'>
            <h1>TIPS FOR NIGHT DRIVING</h1>
            <p>
              “EVEN THOUGH NO TYPE OF DEATH IS ENVIABLE, IT IS BETTER TO DIE IN
              COMFORT THAN TO BE CUT TO DEATH IN BITS LIKE A BULL SLAUGHTERD FOR
              MERRY MAKING”
            </p>
            <div className='writer'>
              <p className='name'>Dr. Kelechi Ngwaba</p>
              <p>B.Sc, MBA, ACCA,</p>
              <p>MARCH 2006</p>
            </div>
          </div>
          <div className='freeheroimg d-none d-md-block'>
            <img src={freeimg} alt='' />
          </div>
        </div>
      </div>
      <div className='safetyhabits my-4'>
        <h5>SAFETY HABITS FOR DRIVERS</h5>
        <p>
          The safety habits for driver’s exposed in this discourse are
          represented by the acronym:
        </p>
        <p>D, A, S2, C, O, A, T2, C, O4, W, D2,</p>
        <p>
          DAS2 COA3 TCO3 WD is one of the longest acronym’s dedicated to
          ensuring safety on our high ways and local trunks. It has gone steps
          beyond safety exposures discussed by safety experts in Nigeria and
          beyond
        </p>
        <table className='tableacronmy'>
          <thead>
            <tr>
              <th scope='col'>Acronym</th>
              <th colspan='2'>Meaning</th>
            </tr>
          </thead>
          <tbody>
            {acronmys.map((acromny) => (
              <tr>
                <td>{acromny.acromny}</td>
                <td colspan='2' className='tabletitle'>
                  {acromny.Meaning}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {/* <img src={acromny} alt='' /> */}
      </div>
      <div className='tipss my-5'>
        <h2>10 TIPS FOR NIGHT DRIVING</h2>
        <p>
          In as much as the NO NAP Anti Drowsy Alert System is a good product,
          it must be emphasized here that it is not the only therapy to fatigued
          driving. Fatigued driving should be avoided as much as possible.
          Traffic death rates are three times greater at night than during the
          day, yet many, especially luxury bus drivers are unaware of night
          driving special hazards or don’t know effective ways to deal with
          them. It is on record at the FRSC that 75% of all night accidents are
          caused by sleep. So in this web page, we are going to highlight on 10
          tips of night driving.
        </p>
        <h6>
          Effective measures to minimize these after – dark dangers can be taken
          by preparing your car and following special guidelines while you
          drive:
        </h6>
        <ul className='effective'>
          <li>Prepare your car for night driving</li>
          <li>
            Clean headlights, taillights, signal light and windows once a week,
            more often if necessary.
          </li>
          <li>
            Aim your headlights properly. Misaimed headlights blind other
            drivers and reduce your ability to see the road.
          </li>
        </ul>
        <dl className='tips'>
          <dt>1. DON’T DRINK AND DRIVE:</dt>
          <dd>
            Not only does alcohol severely impair your driving ability, but it
            also acts as a depressant. Just one drink can induce fatigue. Avoid
            smoking when you drive. Smoke’s nicotine and carbon monoxide hamper
            night vision. Turn your headlight on if there are any doubts. Lights
            will help you see better in early twilight, but they’ll make easier
            for other drivers to see you. Being seen is as important as you
            seeing. Reducing your speed and increase your following distances.
            It is more difficult to judge other vehicle’s speeds and distances
            at night. Don’t overdrive your headlights. You should be able to
            stop inside the illuminated area. If you’re not, you are creating a
            blind crash area in front of your vehicle. Keep your headlights on
            low beams when following another vehicle so you don’t blind the
            driver ahead of you. If an oncoming vehicle doesn’t lower beams from
            high to low, avoid glare by watching the right edge of the road and
            using it as a steering guide. If you have car trouble, pull off the
            road as far as possible. Warn approaching traffic at once by setting
            up flares or reflecting triangles near your vehicle and 300 feet
            behind it. Turn on flashes and the dome light.
          </dd>
          <dt>2. OBSERVE NIGHT DRIVING SAFETY AS SOON AS THE SUN GOES DOWN</dt>
          <dd>
            Your eyes are adapting to the constant change in amount of light put
            on your driving light. Twilight is one of the most difficult time to
            drive
          </dd>
          <dt>3. HAVE A CLEAR HEAD</dt>
          <dd>
            Make sure you always have a clear head before deciding to operate a
            motor vehicle. Alcohol and certain drugs, both illegal and legal,
            can severally impair you driving skills. Many prescription and over
            – the count medications can cause dangerous drowsiness. Get a good
            night’s rest and don’t drive for long stretches without a break. If
            you are tired, don’t risk the safety of yourself and others on the
            highway by trying to drive. Designate a driver or choose another
            means of transportation such a taxicab or public transport.
          </dd>
          <dt>4. LIMIT DRIVING ALONE WHEN TIRED:</dt>
          <dd>
            Driving with someone else in your vehicle, can increase your overall
            alertness. It is well recognized that when driving deprived and at
            night, your chances of a crash are dramatically increased.
          </dd>
          <dt>5. READ THE LABELS:</dt>
          <dd>
            If you are taking any medications, be sure to read and obey the
            warning labels. If the label says the medication cause drowsiness,
            do not drive . Heed to the warning as it is there for a reason.
            Consult with your doctor or pharmacist if you have any.
          </dd>
          <dt>6. PLAN AHEAD:</dt>
          <dd>
            Driving with someone else in your vehicle, can increase your overall
            alertness. It is well recognized that when driving deprived and at
            night, your chances of a crash are dramatically increased.
          </dd>
          <dt>7. RELAX:</dt>
          <dd>
            Avoid aggressive driving by relaxing and having patience. By not
            being such in a rush to reach your destination you will be a calmer
            person and won’t need to speed and run red lights. A yellow light
            means slow down not speed up. Always stop at red light.
          </dd>
          <dt>8. BE ALERT TO SIGNS OF FATIGUE:</dt>
          <dd>
            If you start to feel tired when driving pull over in a safe area and
            let someone else drive. If you are alone, pull into a safe location
            such as a well – lit rest stop and take short nap or get out of the
            car and walk around for a few minutes. Stop as often as necessary.
            When traveling on long trips, eat light food. Large, heavy meals can
            make you drowsy.
          </dd>
          <dt>9. PRACTICE COMMON SENSE SAFETY RULES:</dt>
          <dd>
            Always wear your safety belt and make sure all your passengers are
            buckled properly, even on short trips. If traveling with children,
            educate yourself on the many kinds of child safety seats and
            restraints. Choose which system is best for your child and always
            follow the direction. Make sure children ages 12 and under are
            always buckled up in the back seat, the safest place to ride.
          </dd>
          <dt>10. KEEP YOUR EYES ON THE ROAD:</dt>
          <dd>
            Avoid taking your eyes off the road by eliminating any possible
            distractions ahead of time. Before setting out to drive, be sure
            that important items are within easy reach, i.e. directions and
            maps, sunglasses etc. reduce to minimum possibly dangerous
            diversions of your attention from the task of safe driving, such as
            changing tapes or compact discs and always pull over at a safe place
            to use your cellular telephone.
          </dd>
        </dl>
      </div>
      <div className='offences'>
        <h3>OFFENCES AND PENALTIES</h3>
        <p>
          NOTICE OF FRSC OFFENCE SHEET FOR PURPOSE OF GENERAL ENLIGHTENMENT.
          PURSUANT TO SECTION 10(4), 28(2) OF FRSC (ESTABLISHMENT) ACT, 2007 AND
          REGULATION 220 OF NRTR
        </p>
        {/* <>
          For the purpose of general enlightenment, find below a table showing
          offences and penalties on Nigerian Roads as stipulated by the Federal
          Road Safety Corps (FRSC).
        </> */}
        <table className='tableoffence mb-3'>
          <thead>
            <tr>
              <th scope='col'>S/NO</th>
              <th colspan='2'>TICK INFRINGEMENT (S)</th>
              <th scope='col'>CODE</th>
              <th scope='col'>POINTS</th>
              <th scope='col'>PENALTY</th>
              <th scope='col'>CATEGORY</th>
            </tr>
          </thead>
          <tbody>
            {offences.map((offence) => (
              <tr>
                <td>{offence.id}</td>
                <td colspan='2' className='tabletitle'>
                  {offence.title}
                </td>
                <td>{offence.code}</td>
                <td>{offence.points}</td>
                <td>{offence.penalty}</td>
                <td>{offence.category}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <p className='note'>
          NOTE: Custody fee on impounded Motor vehicle and Motorcycle/tricycle
          is N200.00 and N100, respectively per day payable after initial 24
          hours of grace.
          <br /> Do you wish to waive your Right to a court trial ? Yes or No
        </p>
        <p className='note'>
          NOTE: Custody fee on impounded Motor vehicle and Motorcycle/Tricycle
          is N200.00 per day payable after initial 24 hours of grace
        </p>
        <p className='note'>
          If Yes, please pay the prescribed fine(s) into Federal Government
          Revenue Account in any of the commercial Banks or online using Remita
          platform accessible through http://www.remita.net (select “Pay FG
          Agency; Select FRSC under MDAs; select “Offences” to pay for fines.
          Complete the other mandatory fields as applicable). Bring the teller /
          printout to the FRSC office where the booking was made.
        </p>
        <h3>FRSC News Updates</h3>
        <p className='freelinks'>
          FRSC To Restrategise For Improved Operations, Deploy More Operational
          Equipments, Personnel To Traffic And Crash Prone Areas Nationwide
          <a
            href='https://frsc.gov.ng/frsc-to-restrategise-for-improved-operations-
deploy-more-operational-equipments-personnel-to-traffic-and-crash-prone-
areas-nationwide/'
            target='_blank'
            rel='noopener noreferrer'
            className='mx-2'
          >
            Read More
          </a>
        </p>
        <p className='freelinks'>
          Spy Number Plate Ban: Corps Marshal Commends IGP, Tasks Commanding
          Officers To Compliment Nigerian Police To Enforce Compliance
          <a
            className='mx-2'
            href='https://frsc.gov.ng/spy-number-plate-ban-corps-marshal-commends-igp-tasks-commanding-officers-to-compliment-nigerian-police-to-enforce-compliance/'
            target='_blank'
            rel='noopener noreferrer'
          >
            Read More
          </a>
        </p>
        <p className='freelinks'>
          Application For Admission Into Full-Time Postgraduate Programmes In
          2021/2022 Academic Session
          <a
            className='mx-2'
            href='https://frsc.gov.ng/pg-application-form.pdf'
            target='_blank'
            rel='noopener noreferrer'
          >
            Read More
          </a>
        </p>
        <p className='freelinks'>
          Nigeria Road Safety Strategy II (2020 – 2030)
          <a
            className='mx-2'
            href='https://frsc.gov.ng/wp-
content/uploads/2021/01/NRSS_2021-2030.pdf'
            target='_blank'
            rel='noopener noreferrer'
          >
            Read More
          </a>
        </p>
        <p className='freelinks'>
          Nigeria Road Safety Strategy ( 2014 – 2018)
          <a
            className='mx-2'
            href='https://frsc.gov.ng/nrss2016.pdf'
            target='_blank'
            rel='noopener noreferrer'
          >
            Read More
          </a>
        </p>
        <p className='freelinks'>
          United Nations General Assembly Resolution 2020
          <a
            className='mx-2'
            href='https://frsc.gov.ng/wp-content/uploads/2021/02/UNGA-Resolution-2020.pdf'
            target='_blank'
            rel='noopener noreferrer'
          >
            Read More
          </a>
        </p>
        <p className='freelinks'>
          Status Report of the UN Decade of Action 2011 – 2020
          <a
            className='mx-2'
            href='https://frsc.gov.ng/wp-
content/uploads/2021/01/STATUS_REPORT_OF_THE_UN_DECADE_OF_A
CTION_2011-2020-2_compressed.pdf'
            target='_blank'
            rel='noopener noreferrer'
          >
            Read More
          </a>
        </p>
        <p className='freelinks'>
          GIFMIS Code For Payment of Traffic Offences (Fines)
          <a
            className='mx-2'
            href='
https://frsc.gov.ng/wp-content/uploads/2018/10/GIFMISCODE.pdf'
            target='_blank'
            rel='noopener noreferrer'
          >
            Read More
          </a>
        </p>
      </div>
    </>
  )
}

export default FreeTips
