import React from 'react'
import Gps from '../Compnents/landingpages/Services/GpsTracking/Gps'
import Footer from '../Footer'
import Header from '../Header'
import Layout from './Layout'
// import { useLocation } from 'react-router-dom'


const GpsTracking = (props) => {
    //  const routepath = useLocation()
    //  useEffect(() => {
    //    window.scrollTo(0, 0)
    //  }, [routepath])
  return (
    <>
      <Layout>
        <Header />
        <Gps />
        <Footer />
      </Layout>
    </>
  )
}

export default GpsTracking
