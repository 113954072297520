import React from 'react'
import Footer from '../Footer'
import Header from '../Header'
import Layout from './Layout'
import Faqq from '../Compnents/landingpages/Faqpage/Faqq'
// import { useLocation } from 'react-router-dom'

const Faqpage = (props) => {
  //  const routepath = useLocation()
  //  useEffect(() => {
  //    window.scrollTo(0, 0)
  //  }, [routepath])
  return (
    <>
      <Layout>
        <Header />
        <Faqq/>
        <Footer />
      </Layout>
    </>
  )
}

export default Faqpage
