import React from 'react'
// import { useLocation } from 'react-router-dom'


import '../GpsTracking/Gps.css'
const Gps = () => {
  return (
    <>
    <h1 className='coming my-5'>COMING SOON</h1>
    </>
  )
}

export default Gps