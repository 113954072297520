import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import './App.css'
import Nonapkit from './Pages/Nonapkit'
import Speedlimiters from './Pages/Speedlimiters'
// import Training from './Compnents/landingpages/Services/Training'
// import Footer from './Footer'
// import Header from './Header'
import Contact from './Pages/Conctact'
import Home from './Pages/Home'
import Training from './Pages/Training'
import AboutUs from './Pages/AboutUs'
import Faqpage from './Pages/Faqpage'
import Freetip from './Pages/Freetip'
import GpsTracking from './Pages/GpsTracking'
import SmoothScroll from './Compnents/SmoothScroll'

function App() {
  if (window.location.href.includes('http://nonapnigeria.com/')) {
    window.location.href = 'https://nonapnigeria.com/'
  }
  return (
    <>
      <Router>
        <SmoothScroll>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/contactUs' element={<Contact />} />
            <Route path='/nonap' element={<Nonapkit />} />
            <Route path='/training' element={<Training />} />
            <Route path='/speedlimiters' element={<Speedlimiters />} />
            <Route path='/AboutUs' element={<AboutUs />} />
            <Route path='/faq' element={<Faqpage />} />
            <Route path='/gpstracking' element={<GpsTracking />} />
            <Route path='/freetips' element={<Freetip />} />
          </Routes>
        </SmoothScroll>
      </Router>
    </>
  )
}

export default App
